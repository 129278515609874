import React, { memo, useLayoutEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { Swiper, Scrollbar, Navigation } from 'swiper/dist/js/swiper.esm';
import { useDebouncedCallback } from 'use-debounce';
import { TweenMax } from 'gsap/all';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { customPropTypes } from '../../util';

import './Carousel.scss';

import { useGsapMotion, ease, trackNavigationClick, track, useSectionTimeSpent } from '../../util';

import { ReactComponent as Arrow } from '../../assets/svgs/arrow.svg';

Swiper.use([Scrollbar, Navigation]);

const carouselItemComponents = {
  whitepaper: loadable(() => import('./WhitepaperCarouselItem/WhitepaperCarouselItem')),
  video: loadable(() => import('./VideoCarouselItem/VideoCarouselItem')),
  download: loadable(() => import('./DownloadCarouselItem/DownloadCarouselItem')),
  quote: loadable(() => import('./QuoteCarouselItem/QuoteCarouselItem')),
  logo: loadable(() => import('./LogoCarouselItem/LogoCarouselItem')),
  'founding-members': loadable(() => import('./FoundingMemberCarouselItem/FoundingMemberCarouselItem')),
};

function Carousel({ data: { title, listItems, carouselType, extraCarouselItemProps, name, id } }) {
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const swiperNavigationPrevRef = useRef(null);
  const swiperNavigationNextRef = useRef(null);
  const swiperContainerRef = useRef(null);
  const swiperScrollbarRef = useRef(null);
  const swiperWrapperRef = useRef(null);

  const [handleResize] = useDebouncedCallback(
    useCallback((swiper) => {
      swiper.update();
    }, []),
    1000,
  );

  const animateInit = useCallback(() => {
    TweenMax.set(titleRef.current, {
      opacity: 0,
      y: 20,
    });
    TweenMax.set(swiperWrapperRef.current.childNodes, {
      opacity: 0,
      x: 350,
    });
  }, []);

  const animateIn = useCallback(() => {
    TweenMax.to(titleRef.current, 0.83, {
      opacity: 1,
      y: 0,
      ease: ease.ease1,
    });

    TweenMax.staggerTo(
      swiperWrapperRef.current.childNodes,
      0.16,
      {
        opacity: 1,
        ease: ease.ease7,
        delay: 0.13,
      },
      0.06,
    );

    TweenMax.staggerTo(
      swiperWrapperRef.current.childNodes,
      0.76,
      {
        x: 0,
        ease: ease.ease6,
        delay: 0.13,
      },
      0.06,
    );
  }, []);

  useGsapMotion({ ref: containerRef, animateIn, animateInit });

  useLayoutEffect(() => {
    const swiper = new Swiper(swiperContainerRef.current, {
      freeMode: true,
      freeModeMomentum: false,
      watchOverflow: true,
      speed: 600,
      spaceBetween: 0,
      slidesPerView: 'auto',
      scrollbar: {
        el: swiperScrollbarRef.current,
        draggable: false,
      },
      navigation: {
        nextEl: swiperNavigationNextRef.current,
        prevEl: swiperNavigationPrevRef.current,
      },
      on: {
        transitionStart: () => {
          const isForwards = swiper.translate < swiper.previousTranslate;
          const transformOrigin = isForwards ? 'center left' : 'center right';
          const translateDistance = Math.abs(swiper.translate - swiper.previousTranslate);
          const scaleSwiperContainer = Math.min(Math.max(1 + translateDistance / 3750, 1), 1.1);

          TweenMax.to(swiperContainerRef.current, 0.1, {
            transformOrigin: transformOrigin,
            scaleX: scaleSwiperContainer,
            ease: ease.linear,
            onComplete: () => {
              TweenMax.to(swiperContainerRef.current, 0.435, {
                transformOrigin: transformOrigin,
                scaleX: 1,
                ease: ease.ease7,
              });
            },
          });

          TweenMax.to(swiperWrapperRef.current.childNodes, 0.1, {
            transformOrigin: transformOrigin,
            scaleX: 1 / scaleSwiperContainer,
            ease: ease.linear,
            onComplete: () => {
              TweenMax.to(swiperWrapperRef.current.childNodes, 0.435, {
                transformOrigin: transformOrigin,
                scaleX: 1,
                ease: ease.ease7,
              });
            },
          });

          trackNavigationClick(`Carousel_${isForwards ? 'Right' : 'Left'}_${name}`);
        },
      },
      breakpointsInverse: true,
      breakpoints: {
        1024: {
          freeMode: false,
        },
      },
    });

    window.addEventListener('resize', () => handleResize(swiper));

    return () => {
      window.removeEventListener('resize', () => handleResize(swiper));
      swiper.destroy();
    };
  }, [handleResize, name]);

  const trackCarouselItemClick = useCallback(
    (index) => {
      track('CarouselItemClick', { name: `${name}_${index + 1}` });
    },
    [name],
  );

  useSectionTimeSpent(name, containerRef);

  return (
    <div id={id} className="Carousel" ref={containerRef}>
      <div className="title-container">
        <h3 className="title" ref={titleRef}>
          {title}
        </h3>

        <div className="swiper-navigation">
          <div ref={swiperNavigationPrevRef} className="arrow-container prev">
            <Arrow />
          </div>
          <div ref={swiperNavigationNextRef} className="arrow-container next">
            <Arrow />
          </div>
        </div>
      </div>

      <div ref={swiperContainerRef} className="swiper-container">
        <div className="swiper-wrapper" ref={swiperWrapperRef}>
          {listItems?.map((item, index) => {
            const CarouselItem = carouselItemComponents[item.type || carouselType || 'quote'];

            return (
              <div key={index} onClick={() => trackCarouselItemClick(index)} className="swiper-slide">
                <CarouselItem data={item} {...extraCarouselItemProps} />
              </div>
            );
          })}
        </div>
      </div>

      <div ref={swiperScrollbarRef} className="swiper-scrollbar" />
    </div>
  );
}

Carousel.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    extraCarouselItemProps: PropTypes.shape({
      type: PropTypes.string,
      hasAlternatingColors: PropTypes.bool,
    }),
    // To support mulitple types, specify the type on the item, rather than the carousel
    carouselType: PropTypes.oneOf(['whitepaper', 'video', 'download', 'quote', 'logo', 'founding-members']),
    title: PropTypes.string,
  }).isRequired,
});

export default memo(Carousel);

export const fragments = graphql`
  fragment Carousel on WpPage_Flexiblelayout_FlexibleChildren_Carousel {
    title
    carouselType
    listItems {
      author
      cta {
        file {
          localFile {
            publicURL
          }
        }
        link
        text
      }
      description
      hasAlternatingColors
      icon {
        ...Icon
      }
      image {
        ...FluidImage
      }
      logoBackgroundColor
      title
      type
      videoId
    }
    name
    id
    jumpToLinkTitle
  }

  fragment Carousel_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_Carousel {
    title
    carouselType
    listItems {
      author
      cta {
        file {
          localFile {
            publicURL
          }
        }
        link
        text
      }
      description
      hasAlternatingColors
      icon {
        ...Icon
      }
      image {
        ...FluidImage
      }
      logoBackgroundColor
      title
      type
      videoId
    }
    name
    id
    jumpToLinkTitle
  }
`;
